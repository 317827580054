import React from 'react';
import {
  List,
  Datagrid,
  TextField,
  ShowButton,
  Filter,
  TextInput,
  CreateButton,
  TopToolbar,
} from 'react-admin';

const AccountFilter = (props) => (
  <Filter {...props}>
    <TextInput label="Suche" source="q" alwaysOn />
  </Filter>
);

const AccountActions = () => (
  <TopToolbar>
    <CreateButton />
  </TopToolbar>
);

export default function AccountList(props) {
  return (
    <List actions={<AccountActions />} {...props} filters={<AccountFilter />}>
      <Datagrid>
        <TextField source="id" />
        <TextField source="name" />
        <TextField source="email" />
        <ShowButton />
      </Datagrid>
    </List>
  );
}
