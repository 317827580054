import React from 'react';
import { Show, SimpleShowLayout, TextField, DateField } from 'react-admin';

export default function InvoiceShow(props) {
  return (
    <Show {...props}>
      <SimpleShowLayout>
        <TextField source="id" />
        <TextField source="no" />
        <TextField source="status" />
        <TextField label="Rechnungssteller" source="issuer.name" />
        <DateField
          label="Erstellt am"
          source="created_at"
          showTime
          locales="DE-de"
        />
      </SimpleShowLayout>
    </Show>
  );
}
