import React from 'react';
import { Login } from 'react-admin';

import LoginForm from './Form';

export default function LoginPage() {
  return (
    <Login>
      <LoginForm />
    </Login>
  );
}
