import React from 'react';
import { useSelector } from 'react-redux';
import { MenuItemLink, getResources } from 'react-admin';
import { withRouter } from 'react-router-dom';

import LabelIcon from '@material-ui/icons/Label';
import DefaultIcon from '@material-ui/icons/ViewList';
import PropTypes from 'prop-types';

const Menu = ({ onMenuClick }) => {
  const open = useSelector((state) => state.admin.ui.sidebarOpen);
  const resources = useSelector(getResources);

  return (
    <div>
      {resources.map((resource) => (
        <MenuItemLink
          key={resource.name}
          to={`/${resource.name}`}
          primaryText={
            resource.options && resource.options.label
              ? resource.options.label
              : resource.name
          }
          leftIcon={resource.icon ? <resource.icon /> : <DefaultIcon />}
          onClick={onMenuClick}
          sidebarIsOpen={open}
        />
      ))}
    </div>
  );
};

Menu.propTypes = {
  onMenuClick: PropTypes.func,
};

Menu.defaultProps = {
  onMenuClick: () => {},
};

export default withRouter(Menu);
