/* eslint-disable react/prop-types */
import React from 'react';
import { Field, Form } from 'react-final-form';
import CardActions from '@material-ui/core/CardActions';
import Button from '@material-ui/core/Button';
import TextField from '@material-ui/core/TextField';
import MuiSelect from '@material-ui/core/Select';
import CircularProgress from '@material-ui/core/CircularProgress';
import { makeStyles } from '@material-ui/core/styles';
import { useTranslate, useLogin, useNotify, useSafeSetState } from 'ra-core';

const baseUrls = [
  {
    name: 'Prod ( timbertom.de )',
    value: 'https://backend.timbertom.de',
  },
  {
    name: 'Staging ( demo.timberdata.de )',
    value: 'https://api.demo.timberdata.de',
  },
  {
    name: 'Local Dev ( localhost:8298 )',
    value: 'http://localhost:8298',
  },
  {
    name: 'app.dev.timberdata.de',
    value: 'https://api.dev.timberdata.de',
  },
];

const Input = ({ meta: { touched, error }, input: inputProps, ...props }) => (
  <TextField
    error={!!(touched && error)}
    helperText={touched && error}
    {...inputProps}
    {...props}
    fullWidth
  />
);

const SelectUrl = ({
  meta: { touched, error },
  input: inputProps,
  ...props
}) => {
  return (
    <MuiSelect
      error={!!(touched && error)}
      native
      fullWidth
      {...inputProps}
      {...props}
    >
      {baseUrls.map(({ name, value }) => (
        <option key={name} value={value}>
          {name}
        </option>
      ))}
    </MuiSelect>
  );
};

const useStyles = makeStyles(
  (theme) => ({
    form: {
      padding: '0 1em 1em 1em',
    },
    input: {
      marginTop: '1em',
    },
    button: {
      width: '100%',
    },
    icon: {
      marginRight: theme.spacing(1),
    },
  }),
  { name: 'RaLoginForm' }
);

export default function LoginForm(props) {
  const classes = useStyles(props);
  const login = useLogin();
  const translate = useTranslate();
  const notify = useNotify();
  const [loading, setLoading] = useSafeSetState(false);

  const validate = ({ username, password }) => ({
    username: !username ? translate('ra.validation.required') : undefined,
    password: !password ? translate('ra.validation.required') : undefined,
  });

  const handleOnSubmit = async ({ username, password, baseUrl }) => {
    setLoading(true);

    try {
      await login({ username, password, baseUrl });
      setLoading(false);
    } catch (error) {
      setLoading(false);

      let errorMessage = null;
      if (typeof error === 'undefined' || !error.message) {
        errorMessage = 'ra.auth.sign_in_error';
      } else if (typeof error === 'string') {
        errorMessage = error;
      } else {
        errorMessage = error.message;
      }

      notify(errorMessage, 'warning');
    }
  };

  return (
    <Form
      initialValues={{
        baseUrl: baseUrls[0].value,
      }}
      onSubmit={handleOnSubmit}
      validate={validate}
      render={({ handleSubmit }) => (
        <form onSubmit={handleSubmit} noValidate>
          <div className={classes.form}>
            <div className={classes.input}>
              <Field
                autoFocus
                id="baseUrl"
                name="baseUrl"
                component={SelectUrl}
                label="System"
                disabled={loading}
              />
            </div>
            <div className={classes.input}>
              <Field
                autoFocus
                id="username"
                name="username"
                component={Input}
                label={translate('ra.auth.username')}
                disabled={loading}
              />
            </div>
            <div className={classes.input}>
              <Field
                id="password"
                name="password"
                component={Input}
                label={translate('ra.auth.password')}
                type="password"
                disabled={loading}
                autoComplete="current-password"
              />
            </div>
          </div>
          <CardActions>
            <Button
              variant="contained"
              type="submit"
              color="primary"
              disabled={loading}
              className={classes.button}
            >
              {loading && (
                <CircularProgress
                  className={classes.icon}
                  size={18}
                  thickness={2}
                />
              )}
              {translate('ra.auth.sign_in')}
            </Button>
          </CardActions>
        </form>
      )}
    />
  );
}
