import * as React from 'react';
import { Create, required, SimpleForm, TextInput } from 'react-admin';

export default function WoodTypeCreate(props) {
    return <Create {...props}>
        <SimpleForm>
            <TextInput source="name" validate={[required()]} label="Name" type="text" />
            <TextInput source="short_name" validate={[required()]} label="Short name" type="text" />
        </SimpleForm>
    </Create>
};