const groupProvider = {
  async getList(baseUrl, token, page, perPage, sort, filter) {
    const { field, order } = sort;

    let filterQuery = `&sort_field=${
      field !== 'name' ? field : 'slug'
    }&sort_order=${order}`;

    if (filter && filter.q) {
      filterQuery = `${filterQuery}&search_term=${filter.q}`;
    }
    if (filter && filter.account_id) {
      filterQuery = `${filterQuery}&account_id=${filter.account_id}`;
    }

    const resp = await fetch(
      `${baseUrl}/admin/v3/groups?page=${page}&perPage=${perPage}${filterQuery}`,
      {
        method: 'GET',
        headers: {
          Authorization: `bearer ${token}`,
        },
      }
    );

    if (resp.status !== 200) {
      throw new Error('Request failed');
    }

    const json = await resp.json();
    const data = json.data.map(({ created_at, ...invoice }) => ({
      created_at: new Date(created_at).toLocaleString(),
      ...invoice,
    }));

    return Promise.resolve({
      ...json,
      data,
    });
  },
  async getGroupDetails(baseUrl, token, id) {
    const resp = await fetch(`${baseUrl}/admin/v3/groups/${id}`, {
      method: 'GET',
      headers: {
        Authorization: `bearer ${token}`,
      },
    });

    if (resp.status !== 200) {
      throw new Error('Request failed');
    }

    return resp.json();
  },
  async getGroupFiles(baseUrl, token, id, page, perPage) {
    const resp = await fetch(`${baseUrl}/admin/v3/groups/${id}/files?page=${page}&perPage=${perPage}`, {
      method: 'GET',
      headers: {
        Authorization: `bearer ${token}`,
      },
    });

    if (resp.status !== 200) {
      throw new Error('Request failed');
    }

    return resp.json();
  }
};

export default groupProvider;
