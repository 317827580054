import React from 'react';
import { 
  List, Datagrid, TextField, ShowButton, Filter, ReferenceInput, AutocompleteInput,
  TextInput,
} from 'react-admin';

const GroupFilter = (props) => (
  <Filter {...props}>
    <TextInput label="Suche" source="q" alwaysOn />
    <ReferenceInput
      label="Account"
      source="account_id"
      reference="account"
    >
      <AutocompleteInput />
    </ReferenceInput>
  </Filter>
);

export default function GroupList(props) {
  return (
    <List {...props} filters={<GroupFilter />}>
      <Datagrid>
        <TextField source="id" />
        <TextField source="name" />
        <TextField source="created_at" />
        <ShowButton />
      </Datagrid>
    </List>
  );
}
