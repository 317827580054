import React from 'react';
import PropTypes from 'prop-types';

import Table from '@material-ui/core/Table';
import TableBody from '@material-ui/core/TableBody';
import TableCell from '@material-ui/core/TableCell';
import TableContainer from '@material-ui/core/TableContainer';
import TableHead from '@material-ui/core/TableHead';
import TableRow from '@material-ui/core/TableRow';
import Paper from '@material-ui/core/Paper';

export default function InvoicesTable({ invoices }) {
  return (
    <TableContainer component={Paper}>
      <Table aria-label="simple table">
        <TableHead>
          <TableRow>
            <TableCell>Id</TableCell>
            <TableCell align="left">Created At</TableCell>
            <TableCell align="left">No</TableCell>
            <TableCell align="left">Payed At</TableCell>
            <TableCell align="left">Status</TableCell>
            <TableCell align="left">Receiver City</TableCell>
            <TableCell align="left">Receiver Country</TableCell>
            <TableCell align="left">Receiver Name</TableCell>
            <TableCell align="left">Receiver PostalCode</TableCell>
            <TableCell align="left">Receiver Street</TableCell>
          </TableRow>
        </TableHead>
        <TableBody>
          {invoices.map((invoice) => (
            <InvoicesTableRow key={invoice.id} {...invoice} />
          ))}
        </TableBody>
      </Table>
    </TableContainer>
  );
}

InvoicesTable.propTypes = {
  invoices: PropTypes.array.isRequired,
};

const InvoicesTableRow = ({
  id,
  createdAt,
  no,
  payedAt,
  status,
  receiverCity,
  receiverCountry,
  receiverName,
  receiverPostalCode,
  receiverStreet,
}) => (
  <TableRow>
    <TableCell>{id}</TableCell>
    <TableCell align="left">{new Date(createdAt).toLocaleString()}</TableCell>
    <TableCell align="left">{no}</TableCell>
    <TableCell align="left">{new Date(payedAt).toLocaleString()}</TableCell>
    <TableCell align="left">{status}</TableCell>
    <TableCell align="left">{receiverCity}</TableCell>
    <TableCell align="left">{receiverCountry}</TableCell>
    <TableCell align="left">{receiverName}</TableCell>
    <TableCell align="left">{receiverPostalCode}</TableCell>
    <TableCell align="left">{receiverStreet}</TableCell>
  </TableRow>
);

InvoicesTableRow.propTypes = {
  id: PropTypes.number.isRequired,
  createdAt: PropTypes.string.isRequired,
  no: PropTypes.string.isRequired,
  payedAt: PropTypes.string.isRequired,
  status: PropTypes.string.isRequired,
  receiverCity: PropTypes.string.isRequired,
  receiverCountry: PropTypes.string.isRequired,
  receiverName: PropTypes.string.isRequired,
  receiverPostalCode: PropTypes.string.isRequired,
  receiverStreet: PropTypes.string.isRequired,
};
