const accountProvider = {
  async getOne(baseUrl, token, email) {
    const resp = await fetch(`${baseUrl}/admin/v3/accounts/${email}`, {
      method: 'GET',
      headers: {
        Authorization: `bearer ${token}`,
      },
    });

    if (resp.status !== 200) {
      throw new Error('Request failed');
    }

    const respJson = await resp.json();

    return { data: respJson };
  },
  async getList(baseUrl, token, page, perPage, sort, filter) {
    let filterQuery = `&sort_field=${sort.field}&sort_order=${sort.order}`;

    if (filter && filter.q) {
      filterQuery = `${filterQuery}&search_term=${filter.q}`;
    }

    let perPageQuery = ``;
    if (perPage < 1000) {
      perPageQuery = `&perPage=${perPage}`;
    } else {
      perPageQuery = `&perPage=99999`;
    }

    const resp = await fetch(
      `${baseUrl}/admin/v3/accounts?page=${page}${perPageQuery}${filterQuery}`,
      {
        method: 'GET',
        headers: {
          Authorization: `bearer ${token}`,
        },
      }
    );

    if (resp.status !== 200) {
      throw new Error('Request failed');
    }

    return resp.json();
  },
  async create(baseUrl, token, params) {
    const resp = await fetch(
      `${baseUrl}/admin/v3/accounts/create`,
      {
        method: 'POST',
        headers: {
          Authorization: `bearer ${token}`,
        },
        body: JSON.stringify(params.data)
      }
    );

    if (resp.status >= 400) {
      throw new Error('Request failed');
    }

    return Promise.resolve();
  },
  async postRemoteToken(baseUrl, token, email, short) {
    let query = ``;
    if (short) {
      query = `?short=1`
    }
    const resp = await fetch(`${baseUrl}/admin/v3/accounts/${email}/token${query}`, {
      method: 'POST',
      headers: {
        Authorization: `bearer ${token}`,
      },
    });

    if (resp.status !== 200) {
      throw new Error('Request failed');
    }

    const respToken = await resp.text();

    return { data: respToken };
  },
  async postDoLogin(baseUrl, token, email) {
    const resp = await fetch(`${baseUrl}/admin/v3/accounts/${email}/login`, {
      method: 'POST',
      headers: {
        Authorization: `bearer ${token}`,
      },
      credentials: 'include',
    });

    if (resp.status !== 200) {
      throw new Error('Request failed');
    }
  },
  async postTileset(baseUrl, token, email, tilesetID, layerName) {
    const resp = await fetch(`${baseUrl}/admin/v3/accounts/${email}/tileset`, {
      method: 'POST',
      headers: {
        Authorization: `bearer ${token}`,
      },
      body: JSON.stringify({
        tileset_id: tilesetID,
        layer_name: layerName
      })
    });

    if (resp.status !== 200) {
      throw new Error('Request failed');
    }
  },
};

export default accountProvider;
