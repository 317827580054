import React, { useEffect, useState } from 'react';
import {
  Show,
  SimpleShowLayout,
  TextField,
  useDataProvider,
} from 'react-admin';
import QRCode from 'qrcode'

import Button from '@material-ui/core/Button';
import MuiTextField from '@material-ui/core/TextField';
import Checkbox from '@material-ui/core/Checkbox';
import FormControlLabel from '@material-ui/core/FormControlLabel';
import Box from '@material-ui/core/Box';
import PropTypes from 'prop-types';
import { ACCOUNT_PROVIDER } from '../../providers';

export default function AccountShow(props) {
  const dataProvider = useDataProvider();
  const [loginURL, setLoginURL] = useState('');
  const [qrURL, setQRURL] = useState('');
  const [loginToken, setLoginToken] = useState('');

  const [tilemapID, setTilemapID] = useState('');
  const [layerName, setLayerName] = useState('');
  const [shortFlag, setShortFlag] = useState(false);

  const { id } = props;

  useEffect(() => {
    if (loginToken !== '') {
      setLoginURL(`https://timbertom.de/start/token?token=${loginToken}`);
      QRCode.toDataURL(loginToken).then((url) => {
        setQRURL(url);
      });
    } else {
      setLoginURL('');
      setQRURL('');
    }
  }, [loginToken]);

  const copyLoginURL = () => {
    navigator.clipboard.writeText(loginURL);
  };

  const saveMapData = async () => {
    try {
      const { data: record } = await dataProvider.getOne(ACCOUNT_PROVIDER, { id });
      await dataProvider.postTileset(ACCOUNT_PROVIDER, {
        email: record.email,
        tilemapID,
        layerName
      });
    } catch (e) {
      console.error(e);
    }
  };

  return (
    <Show {...props}>
      <SimpleShowLayout>
        <TextField source="name" />
        <TextField source="email" />
        <FormControlLabel 
          label="Generate Short Token ( valid 10mins )"
          control={<Checkbox value={shortFlag} onChange={v => setShortFlag(v.target.checked)} />}
        />
        <DirectLoginButton />
        <LoginURLButton setLoginToken={setLoginToken} short={shortFlag} />
        <Box display="flex">
          <Box flexGrow={1}>
            <MuiTextField disabled fullWidth value={loginURL} />
          </Box>
          <Box>
            <Button onClick={copyLoginURL}>Copy to clipboard</Button>
          </Box>
        </Box>
        {qrURL !== '' && (
          <Box>
            <p>QR Code für App Login</p>
            <img src={qrURL} alt="QR code" />
          </Box>
        )}
        <Box>
          <Box>
            <MuiTextField label="Tileset ID" placeholder="timberdata.ABC123" value={tilemapID} onChange={v => setTilemapID(v.target.value)} />
          </Box>
          <Box>
            <MuiTextField label="Layer name" placeholder="Some_Name" value={layerName} onChange={v => setLayerName(v.target.value)} />
          </Box>
          <Box paddingTop={2}>
            <Button variant="outlined" onClick={saveMapData}>Set User's map data</Button>
          </Box>
        </Box>
      </SimpleShowLayout>
    </Show>
  );
}

const LoginURLButton = ({ record: { email }, setLoginToken, short = false }) => {
  const dataProvider = useDataProvider();

  const generateLoginURL = async () => {
    const resp = await dataProvider.postRemoteToken(email, short);
    setLoginToken(resp.data);
  };

  return (
    <Button variant="outlined" onClick={generateLoginURL}>
      Generate login URL
    </Button>
  );
};

LoginURLButton.propTypes = {
  record: PropTypes.any.isRequired,
  setLoginToken: PropTypes.func.isRequired,
};

const DirectLoginButton = ({ record: { email } }) => {
  const dataProvider = useDataProvider();

  const doLogin = async () => {
    await dataProvider.postDoLogin(email);
    alert("Success. You are now logged out as admin!");
  };

  return (
    <Button variant="outlined" onClick={doLogin}>
      Login to ERP
    </Button>
  );
};

DirectLoginButton.propTypes = {
  record: PropTypes.any.isRequired,
};