import React, { useState } from 'react';
import PropTypes from 'prop-types';

import { makeStyles } from '@material-ui/core/styles';
import List from '@material-ui/core/List';
import ListItem from '@material-ui/core/ListItem';
import ListItemText from '@material-ui/core/ListItemText';
import ListItemAvatar from '@material-ui/core/ListItemAvatar';
import Avatar from '@material-ui/core/Avatar';
import AccessTimeIcon from '@material-ui/icons/AccessTime';
import AccountCircleIcon from '@material-ui/icons/AccountCircle';
import SubscriptionsIcon from '@material-ui/icons/Subscriptions';
import SupervisorAccountIcon from '@material-ui/icons/SupervisorAccount';
import ReceiptIcon from '@material-ui/icons/Receipt';
import Collapse from '@material-ui/core/Collapse';
import ShowChartIcon from '@material-ui/icons/ShowChart';
import Divider from '@material-ui/core/Divider';
import ExpandLess from '@material-ui/icons/ExpandLess';
import ExpandMore from '@material-ui/icons/ExpandMore';
import { Link } from 'react-router-dom';
import { AccountsTable, InvoicesTable } from './tables';

const useStyles = makeStyles((theme) => ({
  root: {
    width: '100%',
  },
}));

export default function GroupDetailsList({
  id,
  name,
  createdAt,
  subscriptionStatus,
  invoicesTotal,
  activeProductsTotal,
  productsTotal,
  relatedAccounts,
  latestInvoices,
}) {
  const classes = useStyles();

  const [toggleAccountsTable, setToggleAccountsTable] = useState(false);
  const [toggleInvoicesTable, setToggleInvoicesTable] = useState(false);

  const handleClickAccountsTable = () => {
    setToggleAccountsTable(!toggleAccountsTable);
  };

  const handleClickInvoicesTable = () => {
    setToggleInvoicesTable(!toggleInvoicesTable);
  };

  return (
    <List className={classes.root}>
      {/* Name */}
      <ListItem>
        <ListItemAvatar>
          <Avatar>
            <AccountCircleIcon />
          </Avatar>
        </ListItemAvatar>
        <ListItemText primary="Name" secondary={name} />
      </ListItem>
      <Divider variant="inset" component="li" />

      {/* Created at */}
      <ListItem>
        <ListItemAvatar>
          <Avatar>
            <AccessTimeIcon />
          </Avatar>
        </ListItemAvatar>
        <ListItemText
          primary="Created At"
          secondary={new Date(createdAt).toLocaleString()}
        />
      </ListItem>
      <Divider variant="inset" component="li" />

      {/* Subscription Status */}
      <ListItem>
        <ListItemAvatar>
          <Avatar>
            <SubscriptionsIcon />
          </Avatar>
        </ListItemAvatar>
        <ListItemText
          primary="Subscription Status"
          secondary={subscriptionStatus || 'DISABLED'}
        />
      </ListItem>
      <Divider variant="inset" component="li" />

      {/* Files link */}
      <Link to={`/file?filter=%7B"owner_group_id"%3A"${id}"%7D`}>Group Files</Link>
      <Divider variant="inset" component="li" />

      {/* Invoices Total */}
      <ListItem>
        <ListItemAvatar>
          <Avatar>
            <ReceiptIcon />
          </Avatar>
        </ListItemAvatar>
        <ListItemText primary="Invoices total" secondary={invoicesTotal} />
      </ListItem>
      <Divider variant="inset" component="li" />

      {/* (Active) Products Total */}
      <ListItem>
        <ListItemAvatar>
          <Avatar>
            <ShowChartIcon />
          </Avatar>
        </ListItemAvatar>
        <ListItemText
          primary={`Active Products ${activeProductsTotal}`}
          secondary={`Total ${productsTotal}`}
        />
      </ListItem>
      <Divider variant="inset" component="li" />

      {/* Related Accounts */}
      {relatedAccounts && relatedAccounts.length > 0 && (
        <>
          <ListItem button onClick={handleClickAccountsTable}>
            <ListItemAvatar>
              <Avatar>
                <SupervisorAccountIcon />
              </Avatar>
            </ListItemAvatar>
            <ListItemText primary="Related Accounts" />
            {toggleAccountsTable ? <ExpandLess /> : <ExpandMore />}
          </ListItem>
          <Collapse in={toggleAccountsTable} timeout="auto" unmountOnExit>
            <List component="div" disablePadding>
              <AccountsTable accounts={relatedAccounts} />
            </List>
          </Collapse>
          <Divider variant="inset" component="li" />
        </>
      )}

      {/* Latest Invoices */}
      {latestInvoices && latestInvoices.length > 0 && (
        <>
          <ListItem button onClick={handleClickInvoicesTable}>
            <ListItemAvatar>
              <Avatar>
                <ReceiptIcon />
              </Avatar>
            </ListItemAvatar>
            <ListItemText primary="Latest Invoices" />
            {toggleInvoicesTable ? <ExpandLess /> : <ExpandMore />}
          </ListItem>
          <Collapse in={toggleInvoicesTable} timeout="auto" unmountOnExit>
            <List component="div" disablePadding>
              <InvoicesTable invoices={latestInvoices} />
            </List>
          </Collapse>
        </>
      )}
    </List>
  );
}

GroupDetailsList.propTypes = {
  name: PropTypes.string.isRequired,
  createdAt: PropTypes.string.isRequired,
  subscriptionStatus: PropTypes.string.isRequired,
  invoicesTotal: PropTypes.number.isRequired,
  activeProductsTotal: PropTypes.number.isRequired,
  productsTotal: PropTypes.number.isRequired,
  relatedAccounts: PropTypes.array,
  latestInvoices: PropTypes.array,
};

GroupDetailsList.defaultProps = {
  relatedAccounts: [],
  latestInvoices: [],
};
