import React from 'react';
import PropTypes from 'prop-types';

import Table from '@material-ui/core/Table';
import TableBody from '@material-ui/core/TableBody';
import TableCell from '@material-ui/core/TableCell';
import TableContainer from '@material-ui/core/TableContainer';
import TableHead from '@material-ui/core/TableHead';
import TableRow from '@material-ui/core/TableRow';
import Paper from '@material-ui/core/Paper';

export default function AccountsTable({ accounts }) {
  return (
    <TableContainer component={Paper}>
      <Table aria-label="simple table">
        <TableHead>
          <TableRow>
            <TableCell>Id</TableCell>
            <TableCell align="left">Name</TableCell>
            <TableCell align="left">Email</TableCell>
            <TableCell align="left">Role</TableCell>
            <TableCell align="left">City</TableCell>
            <TableCell align="left">Country</TableCell>
            <TableCell align="left">PostalCode</TableCell>
            <TableCell align="left">Street</TableCell>
            <TableCell align="left">Company</TableCell>
          </TableRow>
        </TableHead>
        <TableBody>
          {accounts.map((account) => (
            <AccountTableRow key={account.id} {...account} />
          ))}
        </TableBody>
      </Table>
    </TableContainer>
  );
}

AccountsTable.propTypes = {
  accounts: PropTypes.array.isRequired,
};

const AccountTableRow = ({
  id,
  name,
  email,
  role,
  addressCity,
  addressCountry,
  addressPostalCode,
  addressStreet,
  companyName,
}) => (
  <TableRow>
    <TableCell>{id}</TableCell>
    <TableCell align="left">{name}</TableCell>
    <TableCell align="left">{email}</TableCell>
    <TableCell align="left">{role}</TableCell>
    <TableCell align="left">{addressCity}</TableCell>
    <TableCell align="left">{addressCountry}</TableCell>
    <TableCell align="left">{addressPostalCode}</TableCell>
    <TableCell align="left">{addressStreet}</TableCell>
    <TableCell align="left">{companyName}</TableCell>
  </TableRow>
);

AccountTableRow.propTypes = {
  id: PropTypes.number.isRequired,
  name: PropTypes.string.isRequired,
  email: PropTypes.string.isRequired,
  role: PropTypes.string.isRequired,
  addressCity: PropTypes.string.isRequired,
  addressCountry: PropTypes.string.isRequired,
  addressPostalCode: PropTypes.string.isRequired,
  addressStreet: PropTypes.string.isRequired,
  companyName: PropTypes.string.isRequired,
};
