import React, { useState, useEffect } from 'react';
import PropTypes from 'prop-types';
import {
  useDataProvider,
  Loading,
  Error,
  Show,
  SimpleShowLayout,
} from 'react-admin';

import Details from './Details';

export default function GroupShow(props) {
  const { id } = props;

  const dataProvider = useDataProvider();
  const [groupDetails, setGroupDetails] = useState(null);
  const [loading, setLoading] = useState(true);
  const [error, setError] = useState();

  useEffect(() => {
    const fetchGroupDetails = async () => {
      try {
        const { data } = await dataProvider.getGroupDetails(id);
  
        setGroupDetails(data);
        setLoading(false);
      } catch (err) {
        setError(err);
        setLoading(false);
      }
    };

    fetchGroupDetails();
  }, [ dataProvider, id ]);

  if (loading) {
    return <Loading />;
  }

  if (error) {
    return <Error />;
  }

  if (!groupDetails) {
    return null;
  }

  return (
    <Show {...props}>
      <SimpleShowLayout>
        <Details {...groupDetails} id={id} />
      </SimpleShowLayout>
    </Show>
  );
}

GroupShow.propTypes = {
  id: PropTypes.string.isRequired,
};
