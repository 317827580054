import React from 'react';
import {
  List,
  Datagrid,
  TextField,
  TextInput,
  SelectInput,
  ShowButton,
  Filter,
  AutocompleteArrayInput,
  ReferenceArrayInput,
} from 'react-admin';

export default function InvoiceList(props) {
  return (
    <List {...props} title="Rechnungsliste" filters={<InvoiceFilter />}>
      <Datagrid>
        <TextField source="id" />
        <TextField source="no" />
        <TextField source="created_at" />
        <TextField source="status" />
        <ShowButton />
      </Datagrid>
    </List>
  );
}

const statusMap = {
  UNPROCESSED: 'Noch nicht verarbeitet',
  SEND: 'Rechnung verschickt',
  PAYED: 'Bezahlt',
  CANCELLED: 'Storniert',
};

const InvoiceFilter = (props) => (
  <Filter {...props}>
    <TextInput label="Rechnungsnummer Suche" source="q" alwaysOn />
    <SelectInput
      alwaysOn
      source="status"
      choices={Object.entries(statusMap).map(([id, name]) => ({
        id,
        name,
      }))}
    />
    <ReferenceArrayInput
      label="Verkäufer"
      source="issuer_group_id"
      reference="group"
    >
      <AutocompleteArrayInput />
    </ReferenceArrayInput>
    <ReferenceArrayInput
      label="Käufer"
      source="receiver_account_id"
      reference="account"
    >
      <AutocompleteArrayInput />
    </ReferenceArrayInput>
  </Filter>
);
