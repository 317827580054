import * as React from 'react';
import { Create, required, SimpleForm, TextInput } from 'react-admin';

export default function AccountCreate(props) {
    return <Create {...props}>
        <SimpleForm>
            <TextInput source="email" validate={[required()]} label="E-Mail" type="email" />
            <TextInput source="password" validate={[required()]} label="Password" type="password" />
            <TextInput source="full_name" label="Name" />
        </SimpleForm>
    </Create>
};