import React, { useState } from 'react';
import { Admin, Resource } from 'react-admin';

import {
  ttDataProvider,
  ttAuthProvider,
  ACCOUNT_PROVIDER,
  GROUP_PROVIDER,
  INVOICE_PROVIDER,
  FILE_PROVIDER,
  WOOD_TYPE_PROVIDER,
} from '../providers';

import Layout from '../components/layout';
import LoginPage from '../components/login';
import { AccountShow, AccountList } from '../components/account';
import { GroupShow, GroupList, GroupFiles } from '../components/group';
import { InvoiceShow, InvoiceList } from '../components/invoice';
import AccountCreate from '../components/account/Create';
import { WoodTypeCreate, WoodTypeList } from '../components/wood_types';

const Root = () => {
  const [token, setToken] = useState('');
  const [baseUrl, setBaseUrl] = useState('');

  const [dataProvider, authProvider] = [
    ttDataProvider({
      baseUrl,
      token,
    }),
    ttAuthProvider({
      token,
      setToken,
      setBaseUrl,
    }),
  ];

  const resources = [
    {
      name: ACCOUNT_PROVIDER,
      list: AccountList,
      show: AccountShow,
      create: AccountCreate,
      options: {
        label: 'Accounts ',
      },
    },
    {
      name: GROUP_PROVIDER,
      list: GroupList,
      show: GroupShow,
      options: {
        label: 'Groups ',
      },
    },
    {
      name: INVOICE_PROVIDER,
      list: InvoiceList,
      show: InvoiceShow,
      options: {
        label: 'Invoices ',
      },
    },
    {
      name: FILE_PROVIDER,
      list: GroupFiles,
      options: {
        baseUrl: baseUrl,
        token: token,
        label: 'Files',
      },
    },
    {
      name: WOOD_TYPE_PROVIDER,
      options: {label: "Wood Types"},
      list: WoodTypeList,
      create: WoodTypeCreate,
    }
  ];

  return (
    <Admin
      disableTelemetry
      layout={Layout}
      loginPage={LoginPage}
      dataProvider={dataProvider}
      authProvider={authProvider}
    >
      {resources.map((resource) => (
        <Resource key={resource.name} {...resource} />
      ))}
    </Admin>
  );
};

export default Root;
