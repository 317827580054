import React from 'react';
import {
  List,
  Datagrid,
  TextField,
  CreateButton,
  TopToolbar,
  DeleteButton,
} from 'react-admin';

const WoodTypeActions = () => (
  <TopToolbar>
    <CreateButton />
  </TopToolbar>
);

export default function WoodTypeList(props) {
  return (
    <List actions={<WoodTypeActions />} pagination={<span />} {...props}>
      <Datagrid>
        <TextField source="id" />
        <TextField source="short_name" />
        <TextField source="name" />
        <DeleteButton />
      </Datagrid>
    </List>
  );
}
