import React from 'react';
import { List, Datagrid, TextField, Filter, Button, ReferenceInput, AutocompleteInput } from 'react-admin';

const FileFilter = (props) => (
  <Filter {...props}>
    <ReferenceInput
      label="Gruppe"
      source="owner_group_id"
      reference="group"
      alwaysOn
    >
      <AutocompleteInput />
    </ReferenceInput>
  </Filter>
);

const DownloadButton = ({ record, baseUrl, token }) => (
  <Button onClick={() => {
    fetch(`${baseUrl}/admin/v3/groups/${record.owner_group_id}/files/${record.id}`, {
      method: 'GET',
      headers: {
        Authorization: `bearer ${token}`,
      },
    })
      .then(resp => {
        return resp.blob();
      })
      .then(blob => {
        const url = URL.createObjectURL(blob);
        const a = document.createElement('a');
        a.href = url;
        a.download = record.name;
        a.click();
      })
      .catch(error => {
        console.error(error);
      })
  }} label="Download"/>
);

export default function GroupFiles(props) {
  const { baseUrl, token } = props.options;

  return (
    <List {...props} filters={<FileFilter />}>
      <Datagrid>
        <TextField source="id" />
        <TextField source="name" />
        <DownloadButton baseUrl={baseUrl} token={token} />
      </Datagrid>
    </List>
  );
}
