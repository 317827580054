import accountProvider from './account';
import groupProvider from './group';
import shopProvider from './shop';
import invoiceProvider from './invoice';
import woodTypeProvider from './woodType';

export const SHOP_PROVIDER = 'shop';
export const ACCOUNT_PROVIDER = 'account';
export const GROUP_PROVIDER = 'group';
export const INVOICE_PROVIDER = 'invoice';
export const FILE_PROVIDER = 'file';
export const WOOD_TYPE_PROVIDER = 'wood_types';

export const ttDataProvider = ({ baseUrl, token }) => ({
  getList(resource, { pagination: { page, perPage }, filter, sort }) {
    const args = [baseUrl, token, page, perPage, sort, filter];

    switch (resource) {
      case ACCOUNT_PROVIDER:
        return accountProvider.getList(...args);
      case GROUP_PROVIDER:
        return groupProvider.getList(...args);
      case INVOICE_PROVIDER:
        return invoiceProvider.getList(...args);
      case FILE_PROVIDER:
        return groupProvider.getGroupFiles(baseUrl, token, filter.owner_group_id || 4, page, perPage);
      case WOOD_TYPE_PROVIDER:
        return woodTypeProvider.getList(...args);
      default:
        return Promise.reject();
    }
  },
  getMany(resource, params) {
    if (!params.ids || params.ids.length <= 0) {
      return Promise.reject();
    }

    switch (resource) {
      case ACCOUNT_PROVIDER:
        return Promise.all(params.ids.map(id => {
          return accountProvider.getOne(baseUrl, token, id);
        })).then(resList => {
          return {
            count: resList.length,
            data: resList.map(res => res.data),
          };
        })
      default:
        return Promise.reject();
    }
  },
  getOne(resource, { id }) {
    switch (resource) {
      case ACCOUNT_PROVIDER:
        return accountProvider.getOne(baseUrl, token, id);
      case GROUP_PROVIDER:
        return groupProvider.getGroupDetails(baseUrl, token, id);
      default:
        return Promise.reject();
    }
  },
  update(resource) {
    switch (resource) {
      case SHOP_PROVIDER:
        return shopProvider.updateAll(baseUrl, token);
      default:
        return Promise.reject();
    }
  },
  create(resource, params) {
    switch (resource) {
      case ACCOUNT_PROVIDER:
        return accountProvider.create(baseUrl, token, params);
      case WOOD_TYPE_PROVIDER:
        return woodTypeProvider.create(baseUrl, token, params);
      default:
        return Promise.reject();
    }
  },
  delete(resource, params) {
    switch (resource) {
      case WOOD_TYPE_PROVIDER:
        return woodTypeProvider.delete(baseUrl, token, params);
      default:
        return Promise.reject();
    }
  },
  postRemoteToken(email, short) {
    return accountProvider.postRemoteToken(baseUrl, token, email, short);
  },
  postDoLogin(email) {
    return accountProvider.postDoLogin(baseUrl, token, email);
  },
  getGroupDetails(id) {
    return groupProvider.getGroupDetails(baseUrl, token, id);
  },
  postTileset(resource, payload) {
    if (resource !== ACCOUNT_PROVIDER) {
      return Promise.reject('tileset post only allowed for account');
    }
    return accountProvider.postTileset(baseUrl, token, payload.email, payload.tilemapID, payload.layerName);
  },
});

export const ttAuthProvider = ({ token, setToken, setBaseUrl }) => ({
  async login({ username, password, baseUrl }) {
    const resp = await fetch(`${baseUrl}/inventory/v4/user/token`, {
      method: 'POST',
      body: JSON.stringify({ username, password }),
      credentials: 'include',
    });

    if (resp.status !== 200) {
      throw new Error('Login failed');
    }

    const newToken = await resp.text();

    setBaseUrl(baseUrl);
    setToken(newToken);

    return Promise.resolve();
  },
  logout() {
    setToken('');
    return Promise.resolve();
  },
  checkError() {
    return Promise.resolve();
  },
  checkAuth() {
    return token === '' ? Promise.reject() : Promise.resolve();
  },
  getPermissions() {
    return Promise.resolve('default');
  },
});
