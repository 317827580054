const shopProvider = {
  async updateAll(baseUrl, token) {
    const resp = await fetch(`${baseUrl}/marketplace/v3/shop/update`, {
      method: 'GET',
      headers: {
        Authorization: `bearer ${token}`,
      },
    });

    if (resp.status !== 202) {
      throw new Error('Request failed');
    }

    return { data: { id: 0 } };
  },
  async getShopItems(baseUrl, token, category) {
    const resp = await fetch(
      `${baseUrl}/marketplace/v3/shop/products/${category}`,
      {
        method: 'GET',
        headers: {
          Authorization: `bearer ${token}`,
        },
      }
    );

    if (resp.status !== 202) {
      throw new Error('Request failed');
    }

    return {};
  },
};

export default shopProvider;
