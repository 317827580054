const invoiceProvider = {
  async getList(baseUrl, token, page, perPage, sort, filter) {
    let filterQuery = `&sort_field=${sort.field}&sort_order=${sort.order}`;

    if (filter && filter.q) {
      filterQuery = `${filterQuery}&search_term=${filter.q}`;
    }

    if (filter && filter.status) {
      filterQuery = `${filterQuery}&filter_status=${filter.status}`;
    }

    if (filter && filter.issuer_group_id) {
      filterQuery = `${filterQuery}&filter_issuer_group_id=${filter.issuer_group_id}`;
    }

    if (filter && filter.receiver_account_id) {
      filterQuery = `${filterQuery}&filter_buyer_account_id=${filter.receiver_account_id}`;
    }

    const resp = await fetch(
      `${baseUrl}/admin/v3/invoices?page=${page}&perPage=${perPage}${filterQuery}`,
      {
        method: 'GET',
        headers: {
          Authorization: `bearer ${token}`,
        },
      }
    );

    if (resp.status !== 200) {
      throw new Error('Request failed');
    }

    const json = await resp.json();
    const data = json.data.map(({ created_at, ...invoice }) => ({
      created_at: new Date(created_at).toLocaleString(),
      ...invoice,
    }));

    return Promise.resolve({
      ...json,
      data,
    });
  },
};

export default invoiceProvider;
