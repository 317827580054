const woodTypeProvider = {
  async getList(baseUrl, token, page, _perPage, sort, _filter) {
    let sortField = sort.field;
    if (sortField === 'name') {
      sortField = 'long_name';
    }
    let filterQuery = `sort_field=${sortField}&sort_order=${sort.order}`;

    const resp = await fetch(
      `${baseUrl}/admin/v3/wood_type?${filterQuery}`,
      {
        method: 'GET',
        headers: {
          Authorization: `bearer ${token}`,
        },
      }
    );

    if (resp.status !== 200) {
      throw new Error('Request failed');
    }

    const apiResp = await resp.json();
    // map from api to admin ui expected structure
    return {
      data: apiResp.entries,
      total: apiResp.count,
    }
  },
  async create(baseUrl, token, params) {
    const resp = await fetch(
      `${baseUrl}/admin/v3/wood_type`,
      {
        method: 'POST',
        headers: {
          Authorization: `bearer ${token}`,
        },
        body: JSON.stringify(params.data)
      }
    );

    if (resp.status >= 400) {
      throw new Error('Request failed');
    }

    return Promise.resolve();
  },
  async delete(baseUrl, token, params) {
    const resp = await fetch(
      `${baseUrl}/admin/v3/wood_type/${params.id}`,
      {
        method: 'DELETE',
        headers: {
          Authorization: `bearer ${token}`,
        }
      }
    );

    if (resp.status >= 400) {
      throw new Error('Request failed');
    }

    return Promise.resolve();
  }
};

export default woodTypeProvider;